import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Manual Imports
import { get } from "../../helpers/api_helper"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"

const optionGroup = [
  {
    label: "Search by",
    options: [
      { label: "Email", value: "email" },
    ],
  },
]

const UserList = () => {
  const [users, setUsers] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalSize, setTotalSize] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [search_by, setSearchby] = useState({ label: "Search By", value: null })
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState("")

  const navigate = useNavigate()

  function ClearFilter() {
    console.log("clicking on clear btn")
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null })
    fetchUserList(`alluser/?page_size=${pageSize}`, 20, 1)
  }

  function FilterData(e, values) {
    if (!values.search) {
      toast.error("Enter a value")
      return
    }

    if (!search_by.value) {
      toast.error("Select Search Field")
      return
    }
    // const filter_data = `?${search_by.value}=${values.search}`
    // fetchUserList(`alluser/${filter_data}&page_size=${pageSize}`)
    // setFilter(filter_data)
    toast.info("Filter Option Comming Soon !!")
  }

  useEffect(() => {

    fetchUserList(`alluser/?page_size=${pageSize}`)
  }, [])

  // User list fetch
  const fetchUserList = url => {
    const token = getAccessToken()

    try {
      get(url, {
        headers: { "Content-Type": "application/json", Authorization: token },
        validateStatus: false,
      })
        .then(resp => {
          if (resp.code === "token_not_valid") {
            localStorage.removeItem("authUser")
            navigate("/login")
          }

          if (resp.code >= 400) {
            console.log(resp.code)
          } else {
            setUsers(resp.results)
            setSelectedPage(resp.current)
            setTotalSize(resp.count)
            setPageSize(resp.page_size)
            setTotalPage(resp.total)
            console.log(resp)
            setSpinner(true)
          }
        })
        .catch(error => {
          console.error("Error fetching user list:", error)
        })
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "first_name",
      text: "Name",
      formatter: (cell, row) => (
        <Link
          // to={`/view-user/${row.id}`}
          state={{ userData: row }}
          className="text-dark"
        >
          {cell} {row.last_name}
        </Link>
      ),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "type",
      text: "Type",
      isDummyField: true,
      formatter: (cell, row) => (
        <React.Fragment>
          {row.is_staff && row.is_superuser ? (
            // Render this content if user.is_staff and user.is_superuser are true

            <span class="badge rounded-pill bg-success">superuser</span>
          ) : row.is_staff && row.is_org_owner ? (
            // Render this content if only user.is_staff is true

            <span class="badge rounded-pill bg-warning">Org user</span>
          ) : row.verified_org_user ? (
            // Render this content if only user.is_staff is true
            <span class="badge rounded-pill bg-primary">Co-worker</span>
          ) : (
            // Render this content if none of the above conditions are true
            <span class="badge rounded-pill bg-info">normal user</span>
          )}
        </React.Fragment>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatter: (cell, row) => (
        <React.Fragment>
          <Link to={`/view-user/${row.id}`} className="me-3 text-dark">
            <i className="fa fa-eye"></i>
          </Link>
        </React.Fragment>
      ),
    },
  ]

  function handleTableChange(type, { page, sizePerPage }) {
    window.scrollTo(0, 0)
    fetchUserList(`users/?page_size=${sizePerPage}&page=${page}`)
  }

  function NoDataIndication() {
    if (spinner && users.length === 0) {
      return <p>No Data Found</p>
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </div>
      )
    }
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]
  // Pagination customization
  const pageOptions = {
    page: selectedPage,
    sizePerPage: pageSize,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "200", value: 200 },
    ],
  }

  document.title = "Users | DoddleHQ"
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Dashboard"
            title="Users"
            breadcrumbItem="Users"
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <div className="w-100">
                        <Select
                          value={search_by}
                          placeholder="Search...."
                          onChange={e => {
                            setSearchby(e)
                          }}
                          options={optionGroup}
                          // classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col sm="9">
                      <Link to="#" onClick={()=> toast.info("Comming Soon")}>
                        <button
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="btn btn-primary dropdown-toggle waves-effect waves-light btn btn-primary float-end mb-4"
                        >
                          <i className="fa fa-plus"></i> Add User
                        </button>
                      </Link>
                      {search_by.value !== null && (
                        <Row>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <Input
                                name="name"
                                placeholder={search_by.label}
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <Button
                              type="submit"
                              color="success"
                              className="btn btn-success waves-effect waves-light me-3"
                              onClick={e => FilterData(e, { search: search })}
                            >
                              Filter
                            </Button>{" "}
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => ClearFilter()}
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Clear
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={users}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={users}
                        columns={columns}
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true,
                          cellEdit: true,
                        }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                              />
                              {/* )} */}
                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination  mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default UserList
