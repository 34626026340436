export function toTitleCase(str) {
    let splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    str = splitStr.join(' ')
    let resplitStr = str.split('-');
    for (var j = 0; j < resplitStr.length; j++) {
        resplitStr[j] = resplitStr[j].charAt(0).toUpperCase() + resplitStr[j].substring(1);     
    }
    return resplitStr.join('-');
 }


export function getFullDate(date){
    let d = new Date(date)
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
}

export function slugify(string) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }
