import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  FormGroup,
  Form,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { connect, useDispatch } from "react-redux";
import withRouter from 'components/Common/withRouter';

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/user-4.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { put } from 'helpers/api_helper';

const UserProfile = props => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [idx, setidx] = useState(1);
  const [userData, setUserData] = useState("")
  
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("authUser"));
    setUserData(userData.user)
    setEmail(userData.user.email);
    setName(userData.user.username);
  }, [props.success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      // idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your User Name"),
    }),
    onSubmit: (values) => {
      alert("click")
      dispatch(editProfile(values));
   

    }
    
  });


  document.title = "Profile | Veltrix - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumb maintitle="Dashboard" title="Profile" />

          <Row>
            <Col lg="12">
              {props.error && props.error ? (
                <Alert color="danger">{props.error}</Alert>
              ) : null}
              {props.success ? (
                <Alert color="success">{props.success}</Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: {userData.id} </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Profile</h4>

          <Card>
            <CardBody>

              <Form
                className="form-horizontal"
                
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">First name</Label>
                          <Input
                            name="first_name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name || userData.first_name}
                            invalid={
                              validation.touched.first_name &&
                                validation.errors.first_name
                                ? true
                                : false
                            }
                          />
                          {}
                          {validation.touched.first_name &&
                            validation.errors.first_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.first_name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom06">Last Name</Label>
                          <div className="input-group has-validation">
                            {/* <span className="input-group-text" id="inputGroupPrepend">@</span> */}
                            <Input
                              name="last_name"
                              type="text"
                              className="form-control"
                              id="validationCustom06"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || userData.last_name}
                              invalid={
                                validation.touched.last_name &&
                                  validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                              validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Email</Label>
                          <Input
                            name="email"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || userData.email}
                            invalid={
                              validation.touched.email &&
                                validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom04">Phone Number</Label>
                          <Input
                            name="phone_number"
                            type="text"
                            className="form-control"
                            id="validationCustom04"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_number || userData.phone_number}
                            invalid={
                              validation.touched.phone_number &&
                                validation.errors.phone_number
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone_number &&
                            validation.errors.phone_number ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone_number}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                <div className="text-left mt-4">
                  
                  <Button type="submit"  color="danger">
                    submit
                  </Button>
                  
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, { editProfile, resetProfileFlag })(UserProfile)
);
