import React from "react";

// Profile
import UserProfile from "../pages/Authentication/user-profile";



// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ChangePassword from "../pages/Authentication/ChangePassword";
import FrgotPassword from "../pages/Authentication/FrgotPassword.js";



// Dashboard
import Dashboard from "../pages/Dashboard/index";

// // Users routes
import UserList from "../pages/Users/UsersList";




import Pricing from "pages/Pricing";



import Categories from "pages/Checklists/Categories";
import NewChecklistCategory from "pages/Checklists/Categories/NewCategory";
import EditCategory from "pages/Checklists/Categories/EditCategory";


import Checklists from "pages/Checklists/Checklist";
import AddChecklist from "pages/Checklists/Checklist/AddChecklist";
import CheckListDetail from "pages/Checklists/Checklist/CheckListDetail";

import Tools from "pages/Checklists/Tools";
import ToolCategoryDetail from "pages/Checklists/Tools/Caregory/CategoryDetail";


const userRoutes = [

  // Dashbaord
  { path: "/dashboard", component: <Dashboard /> },

  { path: "/plans", component: <Pricing /> },

  // // Profile

  // // users
  {path: "/user-list", component: <UserList />},


  {path: "/checklist-category", component: <Categories />},
  {path: "/new-checklist-category", component: <NewChecklistCategory />},
  {path: "/checklist-category/:id", component: <EditCategory />},

  {path: "/checklists", component: <Checklists />},
  {path: "/add-checklist", component: <AddChecklist />},
  {path: "/checklist/:id", component: <CheckListDetail />},



  {path: "/tools", component:<Tools/>},
  {path: "/tool-category/:id", component:<ToolCategoryDetail/>},



  // dashboard
  { path: "/", component: <Dashboard /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },
  { path: "/changepassword", component: <ChangePassword /> },
  { path: "/forgot-password", component: <FrgotPassword /> },




  // // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> }, 


];

export { userRoutes, authRoutes };
