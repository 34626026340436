import PropTypes from 'prop-types';
import React from "react";
import { Row, Col, Alert, Card, CardBody, Container, Form, FormFeedback, Label, Input, DropdownItem } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Redux
import { connect, useDispatch } from "react-redux";

import { Link } from 'react-router-dom';


// import images
import logoSm from "../../assets/images/doddle_fav.png"


const ChangePasswordPage = props => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword: '',
    },      
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string().required("Please Enter the Confirm Password"),
    }),
    // onSubmit: (values) => {
    //   dispatch(userForgetPassword(values, props.history));
    // }
  });

  document.title = "Change Password | Veltrix - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20 p-2">Change Password</h5>
                    <Link to="/index" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>
                <CardBody className="p-4">

                  {props.forgetError && props.forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }} className="mt-5">
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }} className="mt-5">
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="mt-4">

                    <div className="mb-3">
                      <Label className="form-label" htmlor="userpassword">New Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Enter new password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={
                          validation.touched.password && validation.errors.password ? true : false
                        }
                      />
                      <Label className="form-label" htmlor="userpassword">Confirm Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Confirm password"
                        type="password"
                        onChange={validation.handleChange}
                        value={validation.values.password || ""}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.password && validation.errors.password ? true : false
                        }
                      />
                       {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                    </div>

                    <div className="row  mb-0">
                      <div className="col-12 text-end">
                        <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Change Password</button>

                      </div>
                    </div>

                  </Form>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In here </Link> </p>
                <p>
                  © {new Date().getFullYear()} Sugandha. Residents Welfare Association with{" "}
                  <i className="mdi mdi-heart text-danger" /> by ocode
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

// ForgetPasswordPage.propTypes = {
//   forgetError: PropTypes.any,
//   forgetSuccessMsg: PropTypes.any,
//   history: PropTypes.object,
//   userForgetPassword: PropTypes.func
// };

// const mapStatetoProps = state => {
//   const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
//   return { forgetError, forgetSuccessMsg };
// };

export default ChangePasswordPage
