import React, { useState, useEffect } from "react"
// import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"
import { get, post } from "../../../helpers/api_helper"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  Tooltip,
} from "reactstrap"
import classnames from "classnames"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import { slugify } from "helpers/methods"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

const NewChecklistCategory = () => {
  const history = useNavigate()
  const [selectedGroup, setselectedGroup] = useState({
    label: "Select Category",
    value: null,
  })
  const [categoryOptions, setCategoryOptions] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [rawMessage, setrawMessage] = useState("")
  const [activeTabJustify, setactiveTabJustify] = useState("5")
  const [toolTipData, setTooltipData] = useState("")
  const [activeToolTip, setActiveToolTip] = useState(false)

  useEffect(() => {
    fetchCategories()
  }, [])

  function onEditorStateChange(editorState) {
    setEditorState(editorState)
    setrawMessage(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      slug: "",
      page_title: "",
      page_url: "",
      page_description: "",
      page_keyword: "",
      meta_keyword: "",
      robots_meta_tag: "",
      advance_robot_tag: "",
      canonical_tag: "https://www.doddlehq.com",
      twitter_site: "@TwitterDev",
      twitter_title: "",
      twitter_description: "",
      twitter_image: null,
      twitter_app_country: "INDIA",
      twitter_app_name_iphone: "",
      twitter_app_id_iphone: "",
      twitter_app_url_iphone: "",
      twitter_app_name_ipad: "",
      twitter_app_id_ipad: "",
      twitter_app_url_ipad: "",
      twitter_app_name_googleplay: "",
      twitter_app_url_googleplay: "",
      twitter_app_id_googleplay: "",
      og_title: "",
      og_description: "",
      og_image: null,
      index: false,
      follow: false,
    },
    validationSchema: Yup.object({
      page_title: Yup.string().required("This field is required"),
      page_url: Yup.string().required("This field is required"),
      canonical_tag: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .required("Please enter Url"),
    }),
    onSubmit: values => {
      if (selectedGroup.value) {
        let data = {
          page_content: rawMessage,
          parent: selectedGroup.value,
          ...values,
        }
        //   const Token = getAccessToken()
        post(`checklist-category/`, data, {
          headers: {
            "Content-Type": "application/json",
            // Authorization: Token
          },
          validateStatus: false,
        })
          .then(resp => {
            if (resp.code >= 400) {
              toast.error(resp.message)
            } else {
              toast.success(`${resp.name} Created Successfully`)
              history('/checklist-category')
            }
          })
          .catch(error => {
            toast.error("An error occurred")
            console.log(error)
          })
      }
    },
  })

  function handleTooltip(id, data) {
    setActiveToolTip(id)
    setTooltipData(data)
  }

  const fetchCategories = () => {
    // const Token = getAccessToken()

    try {
      get(`checklist-category/?page_size=100`, {
        headers: {
          "Content-Type": "application/json",
          // 'Authorization': Token
        },
        validateStatus: false,
      }).then(resp => {
        if (resp.code >= 400) {
          console.log(resp.message)
        } else {
          //   setCategories(resp.results)
          let option = []
          for (let i = 0; i < resp.results.length; i++) {
            option.push({
              label: resp.results[i].name,
              value: resp.results[i].id,
            })
          }
          setCategoryOptions([
            {
              label: "Categories",
              options: option,
            },
          ])
        }
      })
      // setUsers(response);
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  document.title = `New Checklist Category | DoddleHQ`
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            breadcrumbItem={"New Checklist Category"}
            items={[
              { link: "/", title: "Dashboard" },
              { link: "/checklist-category", title: "Checklist Categories" },
            ]}
          />
          <Form
            className="row g-3 needs-validation mt-4"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
            }}
          >
            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom06">
                            Parent Category
                          </Label>
                          <div className="col-lg-12">
                            <Select
                              value={selectedGroup}
                              onChange={e => {
                                setselectedGroup(e)
                              }}
                              options={categoryOptions}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="name">Name</Label>
                          <Input
                            name="name"
                            placeholder="Category Name"
                            type="text"
                            className="form-control"
                            id="name"
                            onChange={e => {
                              validation.handleChange(e)
                              validation.setFieldValue(
                                "slug",
                                slugify(e.target.value)
                              )
                            }}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="slug">Slug</Label>
                          <Input
                            name="slug"
                            placeholder="Category Slug"
                            type="text"
                            className="form-control"
                            id="slug"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.slug || ""}
                            invalid={
                              validation.touched.slug && validation.errors.slug
                                ? true
                                : false
                            }
                          />
                          {validation.touched.slug && validation.errors.slug ? (
                            <FormFeedback type="invalid">
                              {validation.errors.slug}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="page_title">Page Title</Label>
                          <Input
                            name="page_title"
                            placeholder="Page Title"
                            type="text"
                            className="form-control"
                            id="page_title"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.page_title || ""}
                            invalid={
                              validation.touched.page_title &&
                              validation.errors.page_title
                                ? true
                                : false
                            }
                          />
                          {validation.touched.page_title &&
                          validation.errors.page_title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.page_title}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="page_description">
                            Page Description
                          </Label>
                          <Input
                            name="page_description"
                            placeholder="Page Description"
                            type="textarea"
                            className="form-control"
                            id="page_description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.page_description || ""}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="page_url">Page URL</Label>
                          <Input
                            name="page_url"
                            placeholder="Page URL"
                            type="text"
                            className="form-control"
                            id="page_url"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.page_url || ""}
                            invalid={
                              validation.touched.page_url &&
                              validation.errors.page_url
                                ? true
                                : false
                            }
                          />
                          {validation.touched.page_url &&
                          validation.errors.page_url ? (
                            <FormFeedback type="invalid">
                              {validation.errors.page_url}
                            </FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Label htmlFor="page_title">Page Content</Label>
                        <Editor
                          editorStyle={{
                            height: "350px",
                            border: "solid 2px",
                            padding: "10px",
                          }}
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={onEditorStateChange}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "5",
                      })}
                      onClick={() => {
                        setactiveTabJustify("5")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Meta Details</span>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTabJustify === "7",
                      })}
                      onClick={() => {
                        setactiveTabJustify("7")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="far fa-envelope"></i>
                      </span>
                      <span className="d-none d-sm-block">Social</span>
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTabJustify}>
                  <TabPane tabId="5" className="p-3">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="og_title">Enter Meta Title</Label>
                              <Input
                                name="og_title"
                                placeholder="Enter Meta Title"
                                type="text"
                                className="form-control"
                                id="og_title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.og_title || ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="og_description">
                                Enter Meta Description
                              </Label>
                              <Input
                                name="og_description"
                                placeholder="Enter Meta Description"
                                type="textarea"
                                className="form-control"
                                id="og_description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.og_description || ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="meta_keyword">
                                Enter Meta Keywords
                              </Label>
                              <Input
                                name="meta_keyword"
                                placeholder="Enter Meta Keywords"
                                type="text"
                                className="form-control"
                                id="meta_keyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.meta_keyword || ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="page_keyword">
                                Enter Page Keywords
                              </Label>
                              <Input
                                name="page_keyword"
                                placeholder="Enter Page Keywords"
                                type="text"
                                className="form-control"
                                id="page_keyword"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.page_keyword || ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {/* AdVance Meta Card */}
                    <Card>
                      <CardBody>
                        <CardText>Advance Meta</CardText>
                        <Row className="p-2">
                          <Col md={4}>
                            <Label>Meta Robot</Label>
                          </Col>
                          <Col md={8}>
                            <Row>
                              <Col sm="6">
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    id="index"
                                    name="index"
                                    className="form-check-input"
                                    value="index"
                                    defaultChecked={validation.values.index}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="index"
                                  >
                                    Index
                                  </Label>
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    id="noindex"
                                    value="noindex"
                                    name="index"
                                    className="form-check-input"
                                    defaultChecked={!validation.values.index}
                                  />

                                  <Label
                                    className="form-check-label"
                                    htmlFor="noindex"
                                  >
                                    No Index
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="6">
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    id="follow"
                                    name="follow"
                                    value="nofollow"
                                    className="form-check-input"
                                    defaultChecked={validation.values.follow}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="nofollow"
                                  >
                                    Follow
                                  </Label>
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    id="nofollow"
                                    name="follow"
                                    value="nofollow"
                                    className="form-check-input"
                                    defaultChecked={!validation.values.follow}
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="nofollow"
                                  >
                                    No Follow
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="6">
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    id="noimageindex"
                                    value="noimageindex"
                                    name="robots_meta_tag"
                                    className="form-check-input"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="noimageindex"
                                  >
                                    No Image Index
                                  </Label>
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="form-check form-check-inline">
                                  <Input
                                    type="radio"
                                    id="nosnippet"
                                    name="robots_meta_tag"
                                    className="form-check-input"
                                    value="nosnippet"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="nosnippet"
                                  >
                                    No Snippet
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row className="p-2">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="canonical_tag">
                                  canonical URL{" "}
                                </Label>
                                <Input
                                  name="canonical_tag"
                                  placeholder="https://www.doddlehq.com"
                                  type="text"
                                  className="form-control"
                                  id="canonical_tag"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.canonical_tag || ""}
                                  invalid={
                                    validation.touched.canonical_tag &&
                                    validation.errors.canonical_tag
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.canonical_tag &&
                                validation.errors.canonical_tag ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.canonical_tag}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId="7" className="p-3">
                    <Card>
                      <CardBody>
                        <Input
                          //   className="mb-3"
                          name="twitter_site"
                          type="hidden"
                          value={validation.values.twitter_site || ""}
                        />
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_title">
                                Twitter Title{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_title",
                                      "When a webpage is shared on Twitter, the twitter:title tag provides the title that appears alongside the shared link in a tweet. It helps to provide context to users about the content they're sharing or clicking on."
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_title"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_title"
                                placeholder="Enter Meta Title"
                                type="text"
                                className="form-control"
                                id="twitter_title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.twitter_title || ""}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_description">
                                Twitter Description{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_description",
                                      "You can use this as a more concise description than what you may have on the app store. This field has a maximum of 200 characters"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_description"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_description"
                                placeholder="Enter Meta Title"
                                type="text"
                                className="form-control"
                                id="twitter_description"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_description || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_name_iphone">
                                IPhone APP Name{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_name_iphone",
                                      "Iphone APP NAME refers to the name of the iPhone application being promoted or shared on Twitter."
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_name_iphone"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_name_iphone"
                                placeholder="Enter Meta Title"
                                type="text"
                                className="form-control"
                                id="twitter_app_name_iphone"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_name_iphone ||
                                  ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_id_iphone">
                                IPhone APP ID{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_id_iphone",
                                      "String value, and should be the numeric representation of your app ID in the App Store (.i.e. “307234931”)"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_id_iphone"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_id_iphone"
                                placeholder="IPhone APP ID"
                                type="text"
                                className="form-control"
                                id="twitter_app_id_iphone"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_id_iphone || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_url_iphone">
                                IPhone APP URL{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_url_iphone",
                                      "Your app’s custom URL scheme (you must include ”://” after your scheme name)"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_url_iphone"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_url_iphone"
                                placeholder="IPhone APP URL"
                                type="text"
                                className="form-control"
                                id="twitter_app_url_iphone"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_url_iphone || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_id_ipad">
                                IPAD APP ID{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_id_ipad",
                                      "String value, should be the numeric representation of your app ID in the App Store (.i.e. “307234931”)"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_id_ipad"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_id_ipad"
                                placeholder="IPAD APP ID"
                                type="text"
                                className="form-control"
                                id="twitter_app_id_ipad"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_id_ipad || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_name_ipad">
                                IPAD APP NAME{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_name_ipad",
                                      "IPAD APP NAME in a Twitter app card refers to the name of an iPad application being promoted or shared via Twitter."
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_name_ipad"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_name_ipad"
                                placeholder="IPAD APP NAME"
                                type="text"
                                className="form-control"
                                id="twitter_app_name_ipad"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_name_ipad || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_url_ipad">
                                IPAD APP URL{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_url_ipad",
                                      "Your app’s custom URL scheme"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_url_ipad"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_url_ipad"
                                placeholder="IPAD APP URL"
                                type="text"
                                className="form-control"
                                id="twitter_app_url_ipad"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_url_ipad || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_name_googleplay">
                                GOOGLE PLAY APP NAME{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_name_googleplay",
                                      "Your app’s custom URL scheme"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_name_googleplay"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_name_googleplay"
                                placeholder="GOOGLE PLAY APP NAME"
                                type="text"
                                className="form-control"
                                id="twitter_app_name_googleplay"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values
                                    .twitter_app_name_googleplay || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_id_googleplay">
                                GOOGLE PLAY APP ID{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_id_googleplay",
                                      "String value, and should be the numeric representation of your app ID in Google Play (.i.e. “com.android.app”)"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_id_googleplay"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_id_googleplay"
                                placeholder="GOOGLE PLAY APP ID"
                                type="text"
                                className="form-control"
                                id="twitter_app_id_googleplay"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_id_googleplay ||
                                  ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_url_googleplay">
                                GOOGLE PLAY APP URL{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_url_googleplay",
                                      "Your app’s custom URL scheme"
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_url_googleplay"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_url_googleplay"
                                placeholder="GOOGLE PLAY APP URL"
                                type="text"
                                className="form-control"
                                id="twitter_app_url_googleplay"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values
                                    .twitter_app_url_googleplay || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="twitter_app_country">
                                APP COUNTRY{" "}
                                <i
                                  className="fas fa-question"
                                  onMouseEnter={() =>
                                    handleTooltip(
                                      "twitter_app_country",
                                      "If your application is not available in the US App Store, you must set this value to the two-letter country code for the App Store that contains your application."
                                    )
                                  }
                                  onMouseLeave={() => setActiveToolTip(false)}
                                  id="twitter_app_country"
                                ></i>
                              </Label>
                              <Input
                                name="twitter_app_country"
                                placeholder="APP COUNTRY"
                                type="text"
                                className="form-control"
                                id="twitter_app_country"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={
                                  validation.values.twitter_app_country || ""
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <FormGroup className="float-end">
                    <Button color="primary" type="submit">
                      Save
                    </Button>
                  </FormGroup>
                </CardBody>
              </Card>
            </Row>
          </Form>
          {activeToolTip ? (
            <Tooltip
              // placement="top"
              isOpen={true}
              target={activeToolTip}
            >
              {toolTipData}
            </Tooltip>
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewChecklistCategory
