import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Input,
  Badge,
} from "reactstrap"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Manual Imports
import { get, del } from "../../../helpers/api_helper"
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment-timezone"

const optionGroup = [
  {
    label: "Search by",
    options: [{ label: "Name", value: "search" }],
  },
]

const Checklists = () => {
  const [checklists, setChecklists] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalSize, setTotalSize] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [search_by, setSearchby] = useState({ label: "Search By", value: null })
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState("")

  const [selectedChecklist, setselectedChecklist] = useState(null)
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")

  const navigate = useNavigate()

  function ClearFilter() {
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null })
    fetchChecklists(`checklists/?page_size=${pageSize}`, 20, 1)
  }

  function FilterData(e, values) {
    if (!values.search) {
      toast.error("Enter a value")
      return
    }

    if (!search_by.value) {
      toast.error("Select Search Field")
      return
    }
    // const filter_data = `?${search_by.value}=${values.search}`
    // fetchChecklists(`checklists/${filter_data}&page_size=${pageSize}`)
    // setFilter(filter_data)
    toast.info("Comming Soon !!")
  }

  useEffect(() => {
    fetchChecklists(`checklists/?page_size=${pageSize}`)
  }, [])

  // User list fetch
  const fetchChecklists = url => {
    const token = getAccessToken()

    try {
      get(url, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: token
        },
        validateStatus: false,
      })
        .then(resp => {
          if (resp.code === "token_not_valid") {
            localStorage.removeItem("authUser")
            navigate("/login")
          }

          if (resp.code >= 400) {
            console.log(resp.code)
          } else {
            setChecklists(resp.results)
            setSelectedPage(resp.current)
            setTotalSize(resp.count)
            setPageSize(resp.page_size)
            setSpinner(true)
          }
        })
        .catch(error => {
          console.error("Error fetching user list:", error)
        })
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },

    {
      dataField: "category.name",
      text: "Category",
      formatter: (cell, row) => (
        <Link
        to={`/checklist/${row.id}`}
          state={{ userData: row }}
          className="text-dark"
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: "page_title",
      text: "Name",
      formatter: (cell, row) => (
        <Link
            to={`/checklist/${row.id}`}
          state={{ userData: row }}
          className="text-dark"
        >
          <Badge color="primary" className="rounded-pill bg-primary">
          
            {row.page_url}{" "}
          </Badge>
          {cell}
        </Link>
      ),
    },
    {
      dataField: "update_at",
      text: "Created",
      formatter: cell => (
        moment(cell).format("lll")
      ),
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    //   isDummyField: true,
    //   formatter: (cell, row) => (
    //     <React.Fragment>
    //       <Link
    //         to={"#"}
    //         onClick={() => toast.info("Comming Soon !!")}
    //         className="me-3 text-dark"
    //       >
    //         <i className="mdi mdi-pencil"></i>
    //       </Link>{" "}
    //       <Link
    //         to={"#"}
    //         onClick={() => {
    //           setAlert_confirm(true)
    //           setselectedChecklist(row)
    //         }}
    //         className="me-3 text-danger"
    //       >
    //         <i className="mdi mdi-trash-can"></i>
    //       </Link>
    //     </React.Fragment>
    //   ),
    // },
  ]

  function handleTableChange(type, { page, sizePerPage }) {
    window.scrollTo(0, 0)
    fetchChecklists(`checklists/?page_size=${sizePerPage}&page=${page}`)
  }

  function NoDataIndication() {
    if (spinner && checklists.length === 0) {
      return <p>No Data Found</p>
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </div>
      )
    }
  }

  function handleDelete() {
    del(
      `checklists/${selectedChecklist.id}/`,
      { headers: { "Content-Type": "application/json" } },
      { validateStatus: false }
    ).then(res => {
      if (res.status >= 400) {
        console.log(res, "delete error")
      } else {
        let new_page = page
        if (MainCategory.length < 2) {
          new_page = new_page > 1 ? new_page - 1 : 1
        }
        fetchChecklists(`checklists/?page_size=${pageSize}&page=${new_page}`)
        setSuccess_confirm(true)
        setAlert_confirm(false)
        setDynamic_title("Deleted!")
        setDynamic_description("Category Has been deleted.")
        setselectedChecklist(null)
      }
    })
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]
  // Pagination customization
  const pageOptions = {
    page: selectedPage,
    sizePerPage: pageSize,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "200", value: 200 },
    ],
  }

  document.title = "Checklist checklists | DoddleHQ"
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            breadcrumbItem="checklists"
            items={[
              { link: "/", title: "Dashboard" },
              { link: "#", title: "Checklists" },
            ]}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <div className="w-100">
                        <Select
                          value={search_by}
                          placeholder="Search...."
                          onChange={e => {
                            setSearchby(e)
                          }}
                          options={optionGroup}
                        />
                      </div>
                    </Col>
                    <Col sm="9">
                      <Link to="/add-checklist">
                        <button
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="btn btn-primary dropdown-toggle waves-effect waves-light btn btn-primary float-end mb-4"
                        >
                          <i className="fa fa-plus"></i> Add New Checklist
                        </button>
                      </Link>
                      {search_by.value !== null && (
                        <Row>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <Input
                                name="name"
                                placeholder={search_by.label}
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <Button
                              type="button"
                              color="success"
                              className="btn btn-success waves-effect waves-light me-3"
                              onClick={e => FilterData(e, { search: search })}
                            >
                              Filter
                            </Button>{" "}
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => ClearFilter()}
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Clear
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={checklists}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={checklists}
                        columns={columns}
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true,
                          cellEdit: true,
                        }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                                selectRow={selectRow}
                              />
                              {/* )} */}
                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination  mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {alert_confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDelete()}
          onCancel={() => {
            setAlert_confirm(false)
            setselectedChecklist(null)
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {success_confirm ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess_confirm(false)
            setAlert_confirm(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default Checklists
