import axios from "axios";

const API_URL = "https://www.doddlehq.com/api/";
// const API_URL = "http://127.0.0.1:8000/api/";

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response.data, // Return only the data from the response
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  try {
    const response = await axiosApi.get(url, { ...config });
    return response;
  } catch (error) {
    throw error.response || error; // Rethrow the error with the response object if it exists
  }
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => response);
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then(response => response);
}

export async function patch(url, data, config = {}) {
  return axiosApi.patch(url, { ...data }, { ...config }).then(response => response);
}

export async function del(url, config = {}) 
{
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response)
}
