import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Label,
  FormFeedback,
} from "reactstrap"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import Switch from "react-switch"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Manual Imports
import { get, del, post, patch } from "../../../helpers/api_helper"
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import * as Yup from "yup"
import { useFormik } from "formik"
import { slugify } from "helpers/methods"
const optionGroup = [
  {
    label: "Search by",
    options: [{ label: "Name", value: "search" }],
  },
]

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  )
}

const ToolsCategories = () => {
  const [categories, setCategories] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalSize, setTotalSize] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [search_by, setSearchby] = useState({ label: "Search By", value: null })
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState("")
  const [modal, setmodal] = useState(false)

  const [selectedCategory, setselectedCategory] = useState(null)
  const [parentCategory, setParentCategory] = useState([])
  const [success_confirm, setSuccess_confirm] = useState(false)
  const [alert_confirm, setAlert_confirm] = useState(false)
  const [dynamic_title, setDynamic_title] = useState("")
  const [dynamic_description, setDynamic_description] = useState("")
  const [isParent, setisParent] = useState(false)

  const [selectedParentCategory, setselectedParentCategory] = useState({
    label: "Select Parent Category",
    value: null,
  })

  const Categories = [
    {
      label: "Categoties",
      options: parentCategory,
    },
  ]

  const navigate = useNavigate()

  function ClearFilter() {
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null })
    fetchCategories(
      `manage-tool/?all_parents=true&page_size=${pageSize}`,
      20,
      1
    )
  }

  function FilterData(e, values) {
    if (!values.search) {
      toast.error("Enter a value")
      return
    }

    if (!search_by.value) {
      toast.error("Select Search Field")
      return
    }
    // const filter_data = `?${search_by.value}=${values.search}`
    // fetchCategories(`manage-tool/${filter_data}&page_size=${pageSize}`)
    // setFilter(filter_data)
    toast.info("Comming Soon !!")
  }

  useEffect(() => {
    getParentCategory()
    fetchCategories(`manage-tool/?all_parents=true&page_size=${pageSize}`)
  }, [])

  // User list fetch
  const fetchCategories = url => {
    const token = getAccessToken()

    try {
      get(url, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: token
        },
        validateStatus: false,
      })
        .then(resp => {
          if (resp.code === "token_not_valid") {
            localStorage.removeItem("authUser")
            navigate("/login")
          }

          if (resp.code >= 400) {
            console.log(resp.code)
          } else {
            setCategories(resp.results)
            setSelectedPage(resp.current)
            setTotalSize(resp.count)
            setPageSize(resp.page_size)
            setSpinner(true)
          }
        })
        .catch(error => {
          console.error("Error fetching user list:", error)
        })
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <Link to={`/tool-category/${row.id}`} className="text-dark">
          {cell}
        </Link>
      ),
    },
    {
      dataField: "slug",
      text: "Slug",
      formatter: (cell, row) => (
        <Link to={"#"} className="text-dark">
          {cell}
        </Link>
      ),
    },
    {
      dataField: "description",
      text: "Description",
      formatter: (cell, row) => (
        <Link to={"#"} className="text-dark">
          {cell}
        </Link>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatter: (cell, row) => (
        <React.Fragment>
          <Link
            to={"#"}
            className="me-3 text-dark"
          >
            <i className="mdi mdi-pencil"></i>
          </Link>{" "}
          <Link
            to={"#"}
            onClick={() => {
              setAlert_confirm(true)
              setselectedCategory(row)
            }}
            className="me-3 text-danger"
          >
            <i className="mdi mdi-trash-can"></i>
          </Link>
        </React.Fragment>
      ),
    },
  ]

  function handleTableChange(type, { page, sizePerPage }) {
    window.scrollTo(0, 0)
    fetchCategories(
      `manage-tool/?all_parents=true&page_size=${sizePerPage}&page=${page}`
    )
  }

  function NoDataIndication() {
    if (spinner && categories.length === 0) {
      return <p>No Data Found</p>
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </div>
      )
    }
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      slug: "",
      description: "",
      is_tool: true,
      show_tool: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      slug: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
        if (!selectedParentCategory.value) {
            toast.error("Please Select Parent Category")
            return
        }
        const data = {
        ...values,
        parent: selectedParentCategory.value,
        }

        post(`manage-tool/`, data, {
        headers: { "Content-Type": "application/json" },
        validateStatus: false,
        })
        .then(resp => {
            if (resp.status >= 400) {
            console.log(resp)
            } else {
                console.log(resp)
                toast.success(`${resp.name} Created Successfully`)
            fetchCategories(
                `manage-tool/?all_parents=true&page_size=${pageSize}&page=${selectedPage}`
            )
            setmodal(false)
            if (selectedParentCategory.value) {
                getParentCategory()
            }
            }
        })
        .catch(error => {
            toast.error("An error occurred")
            console.error("Error fetching Post:", error)
        })
    },
  })

  function togModel() {
    if (modal) {
      setselectedParentCategory({
        label: "Select Parent Category",
        value: null,
      })
      setselectedCategory(null)
    }
    setmodal(!modal)
  }

  function getParentCategory() {
    get(`manage-tool/?all_parents=true&page_size=100`, {
      headers: { "Content-Type": "application/json" },
      validateStatus: false,
    }).then(resp => {
      if (resp.status >= 400) {
        console.log(resp, "resp")
      } else {
        let category = resp.results
        let options = []
        for (let i = 0; i < category.length; i++) {
          options.push({ label: category[i].name, value: category[i].id })
        }
        setParentCategory(options)
      }
    })
  }

  function handleDelete() {
    del(
      `manage-tool/${selectedCategory.id}/`,
      { headers: { "Content-Type": "application/json" } },
      { validateStatus: false }
    ).then(res => {
      if (res.status >= 400) {
        console.log(res, "delete error")
      } else {
        let new_page = selectedPage
        if (categories.length < 2) {
          new_page = new_page > 1 ? new_page - 1 : 1
        }
        fetchCategories(
          `manage-tool/?all_parents=true&page_size=${pageSize}&page=${new_page}`
        )
        setSuccess_confirm(true)
        setAlert_confirm(false)
        setDynamic_title("Deleted!")
        setDynamic_description("Category Has been deleted.")
        setselectedCategory(null)
      }
    })
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]
  // Pagination customization
  const pageOptions = {
    page: selectedPage,
    sizePerPage: pageSize,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "200", value: 200 },
    ],
  }

  document.title = "Tools Categories | DoddleHQ"
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            breadcrumbItem="Categories"
            items={[
              { link: "/", title: "Dashboard" },
              { link: "#", title: "Tools" },
            ]}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <div className="w-100">
                        <Select
                          value={search_by}
                          placeholder="Search...."
                          onChange={e => {
                            setSearchby(e)
                          }}
                          options={optionGroup}
                        />
                      </div>
                    </Col>
                    <Col sm="9">
                      <Link to="#" onClick={togModel}>
                        <button
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="btn btn-primary dropdown-toggle waves-effect waves-light btn btn-primary float-end mb-4"
                        >
                          <i className="fa fa-plus"></i> Add Category
                        </button>
                      </Link>
                      {search_by.value !== null && (
                        <Row>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <Input
                                name="name"
                                placeholder={search_by.label}
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <Button
                              type="submit"
                              color="success"
                              className="btn btn-success waves-effect waves-light me-3"
                              onClick={e => FilterData(e, { search: search })}
                            >
                              Filter
                            </Button>{" "}
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => ClearFilter()}
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Clear
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={categories}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={categories}
                        columns={columns}
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true,
                          cellEdit: true,
                        }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                              />
                              {/* )} */}
                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination  mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        size="lg"
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={togModel}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            {selectedCategory
              ? `Update ${selectedCategory.name}`
              : "Add New Category"}
          </ModalHeader>
          <Form
            className="g-3 needs-validation"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
            }}
          >
            <ModalBody>
              <Row>
                <Col sm="6">
                  <Label htmlFor="is_parent">Is Parent Category</Label>
                </Col>
                <Col sm="6">
                  <FormGroup className="mb-3">
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setisParent(!isParent)
                      }}
                      checked={isParent}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {isParent ? null : (
                <Row>
                  <Col md="12">
                    <FormGroup className="mb-3">
                      <Label htmlFor="selected-parent_cat">
                        Parent Category
                      </Label>
                      <div className="col-lg-12">
                        <Select
                          id="selected-parent_cat"
                          value={selectedParentCategory}
                          onChange={e => {
                            setselectedParentCategory(e)
                          }}
                          options={Categories}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="name">Name</Label>
                    <Input
                      name="name"
                      placeholder="Name"
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={e => {
                        validation.handleChange(e)
                        validation.setFieldValue(
                          "slug",
                          slugify(e.target.value)
                        )
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="slug">Slug</Label>
                    <Input
                      name="slug"
                      placeholder="slug"
                      type="text"
                      className="form-control"
                      id="slug"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.slug || ""}
                      invalid={
                        validation.touched.slug && validation.errors.slug
                          ? true
                          : false
                      }
                    />
                    {validation.touched.slug && validation.errors.slug ? (
                      <FormFeedback type="invalid">
                        {validation.errors.slug}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup className="mb-3">
                    <Label htmlFor="description">Description</Label>
                    <Input
                      name="description"
                      placeholder="Description"
                      type="textarea"
                      className="form-control"
                      id="description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={togModel}>
                Close
              </Button>{" "}
              <Button color="primary" type="submit">
                Save changes
              </Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>

      {alert_confirm ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDelete()}
          onCancel={() => {
            setAlert_confirm(false)
            setselectedCategory(null)
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {success_confirm ? (
        <SweetAlert
          success
          title={dynamic_title}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setSuccess_confirm(false)
            setAlert_confirm(false)
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default ToolsCategories
