import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Spinner,
  FormGroup,
  Input,
} from "reactstrap"
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import BootstrapTable from "react-bootstrap-table-next"
import Select from "react-select"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

// Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// Manual Imports
import { get } from "../../../helpers/api_helper"
import { getAccessToken } from "../../../helpers/jwt-token-access/accessToken"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
const optionGroup = [
  {
    label: "Search by",
    options: [{ label: "Name", value: "search" }],
  },
]

const Categories = () => {
  const [categories, setCategories] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalSize, setTotalSize] = useState(1)
  const [spinner, setSpinner] = useState(false)
  const [search_by, setSearchby] = useState({ label: "Search By", value: null })
  const [search, setSearch] = useState("")
  const [filter, setFilter] = useState("")




  const navigate = useNavigate()

  function ClearFilter() {
    setFilter("")
    setSearch("")
    setSearchby({ label: "Search By", value: null })
    fetchCategories(`checklist-category/?page_size=${pageSize}`, 20, 1)
  }

  function FilterData(e, values) {
    if (!values.search) {
      toast.error("Enter a value")
      return
    }

    if (!search_by.value) {
      toast.error("Select Search Field")
      return
    }
    // const filter_data = `?${search_by.value}=${values.search}`
    // fetchCategories(`checklist-category/${filter_data}&page_size=${pageSize}`)
    // setFilter(filter_data)
    toast.info("Comming Soon !!")
  }

  useEffect(() => {
    fetchCategories(`checklist-category/?page_size=${pageSize}`)
  }, [])

  // User list fetch
  const fetchCategories = url => {
    const token = getAccessToken()

    try {
      get(url, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: token
        },
        validateStatus: false,
      })
        .then(resp => {
          if (resp.code === "token_not_valid") {
            localStorage.removeItem("authUser")
            navigate("/login")
          }

          if (resp.code >= 400) {
            console.log(resp.code)
          } else {
            setCategories(resp.results)
            setSelectedPage(resp.current)
            setTotalSize(resp.count)
            setPageSize(resp.page_size)
            setSpinner(true)
          }
        })
        .catch(error => {
          console.error("Error fetching user list:", error)
        })
    } catch (error) {
      console.error("Error fetching user list:", error)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <Link
          to={`/checklist-category/${row.id}`}
          state={{ userData: row }}
          className="text-dark"
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: "parent",
      text: "Parent",
      formatter: (cell, row) => (
        cell? 
        <Link
        to={`/checklist-category/${row.id}`}
          state={{ userData: row }}
          className="text-dark"
        >
          {cell.name}
        </Link>:"-"
      ),
    },
    {
      dataField: "slug",
      text: "Slug",
      formatter: (cell, row) => (
        <Link
        to={`/checklist-category/${row.id}`}
          state={{ userData: row }}
          className="text-dark"
        >
          {cell}
        </Link>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      formatter: (cell, row) => (
        <React.Fragment>
          <Link
            to={"#"}
            onClick={() => handleEdit(row)}
            className="me-3 text-dark"
          >
            <i className="mdi mdi-pencil"></i>
          </Link>{" "}
        </React.Fragment>
      ),
    },
  ]

  function handleTableChange(type, { page, sizePerPage }) {
    window.scrollTo(0, 0)
    fetchCategories(`checklist-category/?page_size=${sizePerPage}&page=${page}`)
  }

  function NoDataIndication() {
    if (spinner && categories.length === 0) {
      return <p>No Data Found</p>
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </div>
      )
    }
  }

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]
  // Pagination customization
  const pageOptions = {
    page: selectedPage,
    sizePerPage: pageSize,
    totalSize: totalSize, // replace later with size(Order),
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: "200", value: 200 },
    ],
  }

  document.title = "Checklist Categories | DoddleHQ"
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            breadcrumbItem="Checklist Categories"
            items={[{"link":"/", "title":"Dashboard"},{"link":"#", "title":"Checklists"}]}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <div className="w-100">
                        <Select
                          value={search_by}
                          placeholder="Search...."
                          onChange={e => {
                            setSearchby(e)
                          }}
                          options={optionGroup}
                        />
                      </div>
                    </Col>
                    <Col sm="9">
                      <Link to="/new-checklist-category" >
                        <button
                          type="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="btn btn-primary dropdown-toggle waves-effect waves-light btn btn-primary float-end mb-4"
                        >
                          <i className="fa fa-plus"></i> Add Category
                        </button>
                      </Link>
                      {search_by.value !== null && (
                        <Row>
                          <Col md="4" className="mb-3">
                            <FormGroup>
                              <Input
                                name="name"
                                placeholder={search_by.label}
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                value={search}
                                onChange={e => setSearch(e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm="4">
                            <Button
                              type="submit"
                              color="success"
                              className="btn btn-success waves-effect waves-light me-3"
                              onClick={e => FilterData(e, { search: search })}
                            >
                              Filter
                            </Button>{" "}
                            <Button
                              type="button"
                              color="primary"
                              onClick={() => ClearFilter()}
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Clear
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    data={categories}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={categories}
                        columns={columns}
                        remote={{
                          filter: true,
                          pagination: true,
                          sort: true,
                          cellEdit: true,
                        }}
                        bootstrap5
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <div className="table-responsive">
                              <BootstrapTable
                                remote
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                                responsive
                                bordered={false}
                                striped={true}
                                hover
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                onTableChange={handleTableChange}
                                noDataIndication={() => NoDataIndication()}
                                selectRow={selectRow}
                              />
                              {/* )} */}
                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="mb-3">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="pagination  mb-2">
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>


    </React.Fragment>
  )
}

export default Categories
